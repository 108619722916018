<template>
    <div>
        <headerOrder></headerOrder>   
        <div class="box">
            <div class="main">
                <div style="margin-top:64px">
                    <img src="./success.png" alt="" srcset="" style="width:64px;height:64px">
                </div>
                <div class="tijiao">
                    提交成功
                </div>
                <div class="tijiao"  style="margin-top:20px;font-size:16px;color:#333">
                    客服会在30分钟时间内与您取得联系
                </div>
                <div class="tijiao" style="margin-top:20px;font-size:16px;color:#333">
                    客服在线时间：周一至周日 9：00-18：00
                </div>
                <div class="orderbox">
                    <div >
                        <div class="dd">
                           <div>
                                    订单ID：{{orderInfo.id}}
                            </div>
                            <div style="margin-left:30px">
                                提交时间：{{orderInfo.createTime}}
                            </div>
                        </div>
                        
                    </div>
                    <div class="order">
                        <div class="tjcg">
                            提交成功
                        </div>
                        <div class="anpaidingc">
                            安排订舱
                        </div>
                        <div class="dchz">
                            订舱回执
                        </div>
                        <div class="dchz">
                            完成
                        </div>
                    </div>
                    <!-- <div class="fzr">
                        <div style="margin-top:20px">
                            负责人{{orderInfo.staffName}}
                        </div>
                        <div style="margin-top:20px">
                            联系方式{{orderInfo.staffPhone}}
                        </div>
                    </div> -->
                </div>
                 <div class="tijiao ckdd" @click="lookInfo">
                    查看订单
                </div>
            </div>
        </div>
    </div>
   
</template>
<script>
import headerOrder from './../common/headerOrder.vue'
export default {
    components:{headerOrder},
    data(){
        return{
            blShow:false,
            fcShow:false,
            border:false,
            value1:false,
            params:{
                dcUserMail:'',
                dcUserPhone:'',
                
                cabinetNum:'1'
            },
            dialogVisible:false,
            imgfileList:[],
            fileList:[],
            hxInfo:{},
            orderInfo:{}
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            console.log(this.$route)
            this.$get('/freight/getFreightOrderDetail4app',{orderId:this.$route.query.orderId}).then(res =>{
                    this.orderInfo = res.data
            })
        },
        lookInfo(){
            this.$router.push('/orderInfo?orderId=' + this.$route.query.orderId)
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.main{
    margin-top: 20px;
    width: 1120px;
    height: 624px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tijiao{
    font-weight: 500;
    font-size: 24px;
    margin-top: 24px;
}
.orderbox{
    margin-top: 40px;
   width: 1080px;
height: 232px;
background: rgba(245, 245, 245, 0.5);
border-radius: 2px;
margin-left: 20px;
}
.order{
    width: 1012px;
    background: url('./backorder.png') no-repeat;
    height: 48px;
    margin-left: 34px;
    display: flex;
}
.tjcg{
    width:270px;
    height: 48px;
    background: url('./tjcg.png') no-repeat;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
    color: rgba(22, 119, 255, 1);
}
.anpaidingc{
     width:250px;
    height: 48px;
    background: url('./apdc.png') no-repeat;
    background-size: cover;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
    color: #fff;
}
.dchz{
     width:250px;
    height: 48px;
    // background: url('./apdc.png') no-repeat;
    background-size: cover;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
}
.dd{
    width: 1012px;
    display: flex;
   justify-content: center;
    margin-top: 70px;
    margin-bottom: 8px;
}
.fzr{
    font-weight: 500;
color: #333333;
    margin-top:10px;
    margin-left: 350px;
}
.ckdd{
   width: 88px;
height: 32px;
background: #1677FF;
color: rgba(255, 255, 255, 1);
line-height: 32px;
text-align: center;
font-size: 14px;
cursor: pointer;
}
</style>