<template>
  <div class="header">
      <div class="cursor" @click="goIndex">
          <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" srcset="" style="height:80px;width:245px;margin-left:160px">
          <!-- <img src="./../../assets/images/logo.png" alt="" srcset="" style="height:54px;width:150px;margin-left:160px"> -->
      </div>
      <div class="flex">
          <!-- <div class="cursor">
              首页
          </div>
          <div style="margin-left: 64px;" class="cursor">
              订单中心
          </div>
          <div style="margin-left: 64px;" class="cursor">
              个人中心
          </div> -->
      </div>   
      <!-- <div class="cursor" v-show="token != ''" >
          登录/注册
      </div> -->
  </div>
</template>

<script>
export default {
    data() {
        return {
            token:'',
            visible:true,
            phone:''
        }
    },
    mounted(){
        let token = localStorage.getItem('token')
		if(!token || token == 'null' || token == '' || token == 'undefined'){
			// next('/login')
		
		}else{
			this.token = token
            let userInfo = localStorage.getItem('userInfo')
            this.phone = JSON.parse(userInfo).user.phone
            // this.
		}
    },
    methods:{
         goIndex(){
            this.$router.push('/index')
        },
        login(){
            this.$router.push('/login')
        },
        order(){
            this.$router.push('/order')
        },
        user(){
             this.$router.push('/user')
        },
        loginOut(){
            localStorage.clear();
            this.$toast.success('退出登录成功');
        }
    }
}
</script>

<style scoped lang="scss">
.header{
    
// font-family:fzcjh;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    font-size: 16px;
    color: #333333;
}
.login {
     font-size: 14px;
    color: #1677FF;
    font-weight: 500;
    align-items: center;   
}
.infoMain{
    display: flex;
    // margin-bottom: 10px;
    // height: 50px;
    // background: red;
}
.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;
}
.box1{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #F5F5F5
}
.loginOut{
    margin-top: 12px;
    display: flex;
    color: #E02020;
    align-items: center;
     justify-content: center;
}
</style>